



























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { PublicationModel, PublicationService, SubRefPublicModel } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { PagedCollection, PagedCollectionFilter } from "@/core/models";

@Component({
  components: {},
})
export default class PublicationsCpt extends AppVue {
  onlyVisible = true;
  results = new PagedCollection<SubRefPublicModel>();
  loading: boolean = false;
  filter: PagedCollectionFilter = {
    page: 1,
    pageSize: 25,
  };
  get isLoading() {
    return this.loading;
  }

  @Watch("filter.page") async pageUpdate() {
    await this.refresh();
  }
  @Watch("onlyVisible") async visiblePublicationUpdate() {
    await this.refresh();
  }
  prevPage() {
    this.filter.page = this.filter.page - 1;
  }
  nextPage() {
    this.filter.page = this.filter.page + 1;
  }
  resetPage() {
    this.filter.page = 1;
  }

  async refresh() {
    if (this.onlyVisible) {
      this.results = await PublicationService.getAllVisiblePublications(this.filter);
    } else {
      this.results = await PublicationService.getAllPublications(this.filter);
    }
  }
  async created() {}

  async mounted() {
    this.refresh();
  }
}
