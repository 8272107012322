

















































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";

import {
  CreditNoteModel,
  PublicationRateModel,
  PublicationService,
  PublicationTypeModel,
  RateTypeModel,
  SearchService,
  SubRefPublicModel,
  SubscriptionModel,
  SubscriptionService,
  TurnstoneLookupsModel,
} from "@/core/services";
import { RadioButtonTristateCpt } from "@/core/components";

import AppVue from "@/AppVue.vue";
import { PagedCollection, EditProfileModel } from "@/core/models";
import moment from "moment";
import CreditNoteTableCpt from "./CreditNoteTableCpt.vue";
import CreditNoteEditorCpt from "./CreditNoteEditorCpt.vue";

export class Type {}

@Component({
  components: {
    CreditNoteTableCpt: () => Promise.resolve(CreditNoteTableCpt),
    CreditNoteEditorCpt: () => Promise.resolve(CreditNoteEditorCpt),
    RadioButtonTristateCpt: () => Promise.resolve(RadioButtonTristateCpt),
  },
})
export default class UserSubscriptionsCpt extends AppVue {
  @Prop({ default: () => [] }) subscriptions: SubscriptionModel[];
  @Prop({ default: 0 }) userProfileId: number;
  @Prop({ default: () => ({}) }) userProfile: EditProfileModel;

  lookups: TurnstoneLookupsModel = new TurnstoneLookupsModel();
  publications = new PagedCollection<SubRefPublicModel>();
  publicationRates = new PagedCollection<PublicationRateModel>();
  rateTypes = new PagedCollection<RateTypeModel>();
  publicationTypes = new PagedCollection<PublicationTypeModel>();
  model = new SubscriptionModel();
  addSubscription = false;
  dialogTitle = "Edit subscriptions";
  filteredOptions: SubRefPublicModel[] = [];
  accountMigrationRules: { [key: string]: string } = {
    ONLINE: "CISONL",
    SELBD: "CISSBD",
    ATRIAL: "CISTRL",
    ICIS1: "CISCTY",
  };
  defaultProps = {
    children: "children",
    label: "name",
  };
  $refs: {
    tree: HTMLFormElement;
  };
  selectedCounties: string[] = [];
  editCreditNoteDialog: boolean = false;
  creditNoteModel: CreditNoteModel = new CreditNoteModel();
  subscriptionStatuses = [
    { code: "A", name: "Active" },
    { code: "C", name: "Cancelled" },
    { code: "E", name: "Expired" },
    { code: "F", name: "Future" },
    { code: "O", name: "On Hold" },
    { code: "P", name: "Provisional" },
    { code: "S", name: "Suspended" },
  ];
  isCancelled: boolean = false;
  activeTab: string = "default";
  get treeData() {
    return this.lookups.countryList;
  }
  pubType(id: number) {
    if (this.publicationTypes.items) {
      return this.publicationTypes.items.find((x) => x.publicationTypeId === id)?.name;
    }
    return "";
  }
  async setExport(value: string) {
    this.model.isExportEnabled = value === "On";
  }
  async setPreventEmailingInvoice(value: string) {
    this.model.preventEmailingInvoice = value === "On";
  }

  async downloadInvoice(subId: number, invoiceId: number) {
    try {
      const result = await SubscriptionService.downloadInvoice(subId);
      if (!result) {
        throw new Error("File not found");
      }
      const filename = `CIS${invoiceId}.pdf`;
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      this.$notify({
        title: "Download completed!",
        type: "success",
        message: `${filename} downloaded successfully`,
      });
    } catch (err) {
      this.$notify({
        title: "Download failed!",
        type: "error",
        message: `The file doesn't exist`,
      });
    }
  }

  async setCountyFilter(value: string) {
    this.model.hasCountyFilter = value === "On";
    this.selectedCounties = this.model.countyList.map((t) => t.name);
    this.updateTree();
  }

  editCreditNote(creditNote: any) {
    this.creditNoteModel = { ...creditNote };
    this.editCreditNoteDialog = true;
  }
  saveCreditNote(updatedCreditNote: any) {
    updatedCreditNote.justUpdated = true;
    const recordIndex = this.model.creditNotes.findIndex((x) => x.creditNoteID === updatedCreditNote.creditNoteID);
    if (recordIndex === -1) {
      this.model.creditNotes.push(updatedCreditNote);
    } else {
      this.model.creditNotes.splice(recordIndex, 1, updatedCreditNote);
    }
    this.editCreditNoteDialog = false;
  }
  updateVat() {
    this.model.vatTotal = this.model.netTotal * (this.userProfile.percentage / 100);
    return this.updateTotal();
  }
  updateTotal() {
    this.model.total = Number(this.model.netTotal) + Number(this.model.vatTotal);
    return true;
  }
  async setVATDefaults() {
    const rateType = this.model.rateTypeId;
    const publicationType = this.model.publicationTypeId;
    const pubCode = this.model.pubCode;

    if (!rateType || !publicationType || !pubCode) {
      return;
    }
    if (!this.publicationRates || !this.publicationRates.items) {
      this.publicationRates = await PublicationService.getAllPublicationRates({
        page: 1,
        pageSize: 100,
      });
    }
    const defaultRate = this.publicationRates.items.find(
      (x) => x.pubCode === pubCode && x.publicationTypeId === publicationType && x.rateTypeId === rateType,
    );
    if (!defaultRate) {
      return;
    }
    this.model.netTotal = defaultRate.rate;
    this.model.vatTotal = defaultRate.rate * (this.userProfile.percentage / 100);
    this.model.total = this.model.netTotal + this.model.vatTotal;
  }
  updateTree() {
    this.$nextTick(() => {
      if (!!this.$refs.tree) {
        this.$refs.tree.setCheckedKeys(this.selectedCounties, true);
      }
    });
  }
  showDialog() {
    this.model = new SubscriptionModel();
    this.model.startDate = moment().toDate();
    this.model.endDate = moment().add(1, "year").add(-1, "day").toDate();
    this.addSubscription = true;
    this.dialogTitle = "Add subscription";
  }
  updatePubCodeDefaults(newPubCode: string) {
    const pub = this.filteredOptions.find((x) => x.pubCode === newPubCode);
    if (!pub) {
      return;
    }
    this.model.projectSearchAllowance = pub.projectSearchAllowance;
    this.model.companySearchAllowance = pub.companySearchAllowance;
    this.model.projectAllowance = pub.projectAllowance;
  }
  updatePeriod(newPeriod: number) {
    if (newPeriod === 1) {
      // annual
      this.model.endDate = moment(this.model.startDate).add(1, "year").add(-1, "day").toDate();
    } else if (newPeriod === 2) {
      // monthly
      this.model.endDate = moment(this.model.startDate).add(1, "month").add(-1, "day").toDate();
    }
  }
  edit(sub: SubscriptionModel) {
    this.model = { ...sub };
    this.addSubscription = true;
    this.dialogTitle = "Edit subscription";
    this.selectedCounties = this.model.countyList.map((t) => t.name);
    this.updateTree();
  }
  filter(f: string) {
    if (f.trim().length === 0) {
      this.filteredOptions = this.publications.items;
      return;
    }
    this.filteredOptions = this.publications.items.filter((x) => x.pubCode.toLowerCase().startsWith(f.toLowerCase()));
  }
  migrate(subId: number) {
    this.$emit("migrate", subId);
  }
  statusChange(val: string) {
    if (val === "C") {
      this.isCancelled = true;
    }
  }
  async created() {
    this.lookups = await SearchService.getLookups();
    this.model = new SubscriptionModel();
    this.model.userProfileId = this.userProfileId;
    this.publications = await PublicationService.getAllPublications({
      page: 1,
      pageSize: 200,
      includeDeleted: false,
    });
    this.rateTypes = await PublicationService.getAllRates({
      page: 1,
      pageSize: 200,
      includeDeleted: false,
    });
    this.publicationTypes = await PublicationService.getAllPublicationTypes({
      page: 1,
      pageSize: 200,
      includeDeleted: false,
    });
    this.filter("");
  }
  async save() {
    this.model.userProfileId = this.userProfileId;
    let updatedModel = new SubscriptionModel();
    if (this.model.hasCountyFilter) {
      this.selectedCounties = this.$refs.tree.getCheckedKeys(true);
      this.model.countyList = this.lookups.countyList.filter((x) => this.selectedCounties.indexOf(x.name) > -1);
    }

    if (!this.model.subscriptionID) {
      updatedModel = await SubscriptionService.addNew(this.model);
    } else {
      updatedModel = await SubscriptionService.update(this.model);
    }
    const toUpdate = this.subscriptions.findIndex((x) => x.subscriptionID === updatedModel.subscriptionID);

    if (toUpdate > -1) {
      this.subscriptions.splice(toUpdate, 1);
    }
    this.subscriptions.unshift(updatedModel);
    this.$emit("update", updatedModel);

    // If the sub has been updated to cancelled we'll send the user to the credit note tab
    if (this.isCancelled) {
      this.activeTab = "credit";
      this.editCreditNote(new CreditNoteModel());
      this.isCancelled = false; // reset the check
    } else {
      this.close();
    }
  }
  async close() {
    this.$nextTick(() => {
      this.model = new SubscriptionModel();
      this.model.userProfileId = this.userProfileId;
    });
    this.addSubscription = false;
    this.model.creditNotes.forEach((x) => (x.justUpdated = false));
  }

  async mounted() {}
}
