







































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { AccountService, SearchService } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { LookupCodeModel, EditProfileModel } from "@/core/models";

@Component({
  components: {},
})
export default class UserFormCpt extends AppVue {
  @Prop({ default: () => new EditProfileModel() }) model: EditProfileModel;
  internalModel: EditProfileModel = new EditProfileModel();
  vatCodes: any[] = [];
  countries: LookupCodeModel[] = [];
  counties: LookupCodeModel[] = [];
  businessTypes: LookupCodeModel[] = [];

  @Watch("internalModel", { deep: true })
  modelUpdated() {
    this.$emit("updated", this.internalModel);
  }

  async created() {
    this.countries = AccountService.getCountriesDropdown();
    this.counties = AccountService.getCountiesDropdown();
    this.businessTypes = AccountService.getBusinessTypesDropdown();
    this.vatCodes = await SearchService.getLookupVatCodes();
    this.internalModel = { ...this.model };
  }

  async mounted() {
    this.internalModel = { ...this.model };
  }
}
