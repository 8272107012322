


































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { PublicationRateModel, PublicationService, RateTypeModel } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { PagedCollection, PagedCollectionFilter } from "@/core/models";
import moment from "moment";

@Component({
  components: {},
})
export default class PublicationRatesCpt extends AppVue {
  onlyVisible = true;
  results = new PagedCollection<PublicationRateModel>();
  loading: boolean = false;
  filter: PagedCollectionFilter = {
    page: 1,
    pageSize: 25,
  };
  pubCodes = ["CISONL", "CISCTY", "CISSBD", "CISTRL", "CISAPI"];
  periods = [
    { k: 1, v: "Annual" },
    { k: 2, v: "Monthly" },
  ];
  labelW = "200px";
  dialogFormVisible = false;
  newRow = new PublicationRateModel();
  rateTypes = new PagedCollection<RateTypeModel>();
  get isLoading() {
    return this.loading;
  }

  @Watch("filter.page") async pageUpdate() {
    await this.refresh();
  }
  @Watch("onlyVisible") async visiblePublicationUpdate() {
    await this.refresh();
  }
  clearAndClose() {
    this.dialogFormVisible = false;
    this.newRow = new PublicationRateModel();
  }
  async save() {
    this.loading = true;
    await PublicationService.savePublicationRate(this.newRow);
    this.clearAndClose();
    this.refresh();
    this.loading = false;
  }
  prevPage() {
    this.filter.page = this.filter.page - 1;
  }
  nextPage() {
    this.filter.page = this.filter.page + 1;
  }
  resetPage() {
    this.filter.page = 1;
  }

  async refresh() {
    if (this.onlyVisible) {
      this.results = await PublicationService.getAllPublicationRatesForVisiblePublications(this.filter);
    } else {
      this.results = await PublicationService.getAllPublicationRates(this.filter);
    }
  }
  async created() {}

  async mounted() {
    this.rateTypes = await PublicationService.getAllRates({ pageSize: 100, page: 1 });
    this.refresh();
  }
}
