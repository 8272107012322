




































































































































import { Component, Vue, Prop } from "vue-property-decorator";

import {
  AccountService,
  ProfileModel,
  PublicationService,
  SubRefPublicModel,
  UserSearchPagedCollectionFilter,
} from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { PagedCollection } from "@/core/models";

@Component({
  components: {},
})
export default class SearchUserCpt extends AppVue {
  loading: boolean = false;

  filter: UserSearchPagedCollectionFilter = {
    search: "",
    pubCode: "All",
    status: "All",
    page: 1,
    pageSize: 15,
  };

  publications = new PagedCollection<SubRefPublicModel>();
  filteredPublications: SubRefPublicModel[] = [];
  results = new PagedCollection<ProfileModel>();
  get isLoading() {
    return this.loading;
  }

  async catchEnter(keyUpEvent: KeyboardEvent) {
    if (keyUpEvent.key === "Enter") {
      await this.search(true);
    }
  }

  filterHandler(f: string) {
    if (f.trim().length === 0) {
      this.filteredPublications = this.publications.items;
      return;
    }
    this.filteredPublications = this.publications.items.filter((x) =>
      x.pubCode.toLowerCase().startsWith(f.toLowerCase()),
    );
  }

  convertQuerystring() {
    const query = this.$route.query;
    if (query.search || query.page || query.pubCode || query.status) {
      this.filter.search = query.search as string;
      this.filter.page = parseInt(query.page as string, 10);
      this.filter.pubCode = query.pubCode as string;
      this.filter.status = query.status as string;
    }
  }

  pageChanged(page: number) {
    this.filter.page = page;
    this.search(true);
  }

  async search(rebuildQueryString: boolean) {
    this.loading = true;
    if (rebuildQueryString) {
      this.$router.replace({
        path: this.$route.params[0],
        query: {
          search: this.filter.search,
          pubCode: this.filter.pubCode,
          status: this.filter.status,
          page: this.filter.page.toString(),
        },
      });
    }
    this.filter.pageSize = 14;
    this.results = await AccountService.SearchUser(this.filter);
    this.loading = false;
  }

  async created() {
    this.updateTitle("Search users");
    this.publications = await PublicationService.getAllPublications({
      page: 1,
      pageSize: 200,
      includeDeleted: false,
    });
    this.filterHandler("");
    this.convertQuerystring();
    this.search(false);
  }

  async mounted() {}
}
