import { render, staticRenderFns } from "./PublicationsCpt.vue?vue&type=template&id=1a6dcde3&scoped=true&"
import script from "./PublicationsCpt.vue?vue&type=script&lang=ts&"
export * from "./PublicationsCpt.vue?vue&type=script&lang=ts&"
import style0 from "./PublicationsCpt.vue?vue&type=style&index=0&id=1a6dcde3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a6dcde3",
  null
  
)

export default component.exports