






















































































































































import { Component, Vue, Prop } from "vue-property-decorator";

import { AccountService } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { LookupCodeModel, EditProfileModel } from "@/core/models";

@Component({
  components: {},
})
export default class UserProfileCpt extends AppVue {
  @Prop({ default: false }) profile: EditProfileModel;
  countries: LookupCodeModel[] = [];

  get percCompanyAccess() {
    return this.capSearches(100 * (this.profile.totalCompanyAccess / this.profile.companyAccessAllowance));
  }
  get percCompanySearch() {
    return this.capSearches(100 * (this.profile.totalCompanySearch / this.profile.companySearchAllowance));
  }
  get percProjectAccess() {
    return this.capSearches(100 * (this.profile.totalProjectAccess / this.profile.projectAccessAllowance));
  }
  get percProjectExport() {
    return this.capSearches(100 * (this.profile.totalProjectExport / this.profile.totalProjectAccess));
  }
  get percProjectSearch() {
    return this.capSearches(100 * (this.profile.totalProjectSearch / this.profile.projectSearchAllowance));
  }
  get percForgottenProjects() {
    return this.capSearches(100 * (this.profile.totalForgottenProjects / this.profile.forgottenProjectsAllowance));
  }
  get formattedAddress() {
    const addressArray: string[] = [];
    if (this.profile.address1) {
      addressArray.push(this.profile.address1);
    }
    if (this.profile.address2) {
      addressArray.push(this.profile.address2);
    }
    if (this.profile.address3) {
      addressArray.push(this.profile.address3);
    }
    if (this.profile.address4) {
      addressArray.push(this.profile.address4);
    }
    if (this.profile.county) {
      addressArray.push(this.profile.county);
    }

    return addressArray.join(", ");
  }
  get countryName() {
    const selected = this.countries.find((i) => i.code === this.profile.country);
    if (selected) {
      return selected.name;
    }

    return this.profile.countyCode;
  }

  creditStatus(input: number) {
    if (input < 85) {
      return null;
    }
    if (input > 98) {
      return "exception";
    }
    return "warning";
  }

  capSearches(input: number) {
    if (input < 0) {
      return 0;
    }
    if (input >= 100) {
      return 100;
    }
    return parseInt(input.toFixed(0), 10);
  }

  async created() {
    this.countries = AccountService.getCountriesDropdown();
  }

  async mounted() {}
}
