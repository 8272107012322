
















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { PublicationService, RateTypeModel } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { PagedCollection, PagedCollectionFilter } from "@/core/models";

@Component({
  components: {},
})
export default class PublicationRateTypesCpt extends AppVue {
  results = new PagedCollection<RateTypeModel>();
  loading: boolean = false;
  filter: PagedCollectionFilter = {
    page: 1,
    pageSize: 25,
  };
  get isLoading() {
    return this.loading;
  }

  @Watch("filter.page") async pageUpdate() {
    await this.refresh();
  }
  prevPage() {
    this.filter.page = this.filter.page - 1;
  }
  nextPage() {
    this.filter.page = this.filter.page + 1;
  }
  resetPage() {
    this.filter.page = 1;
  }

  async refresh() {
    this.results = await PublicationService.getAllRates(this.filter);
  }
  async created() {}

  async mounted() {
    this.refresh();
  }
}
