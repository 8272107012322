









































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { SubscriptionModel, SubscriptionService } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { PagedCollection, PagedCollectionFilter } from "@/core/models";
import moment from "moment";
@Component({
  components: {},
})
export default class ReconciliationsCpt extends AppVue {
  results = new PagedCollection<SubscriptionModel>();
  loading: boolean = false;
  filter: PagedCollectionFilter = {
    page: 1,
    pageSize: 25,
  };
  @Prop({ default: () => moment.utc() }) date: moment.Moment | string;
  get isLoading() {
    return this.loading;
  }

  @Watch("filter.page") async pageUpdate() {
    await this.refresh();
  }
  prevPage() {
    this.loading = true;
    this.filter.page = this.filter.page - 1;
  }
  nextPage() {
    this.loading = true;
    this.filter.page = this.filter.page + 1;
  }
  resetPage() {
    this.loading = true;
    this.filter.page = 1;
  }

  async refresh() {
    this.results = await SubscriptionService.getRolledOverSubscriptions(this.filter, this.date);
    this.loading = false;
  }
  async created() {}

  async mounted() {
    this.refresh();
  }
}
